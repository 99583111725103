@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "./fonts.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShabnamDigit, "IRANSans", "Tahoma";
}
.select_box fieldset,
.select_box svg {
  border: none !important;
  color: #7549c2;
}
#myteam-select {
  color: #7549c2;
  display: flex;
}
#myteam-select_e {
  color: #7549c2;
  display: flex;
  padding-right: 0;
}
body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: ShabnamDigit, "IRANSans", "Tahoma";
}
a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.MuiTypography-root,
.MuiInputBase-input,
.MuiTableCell-root {
  font-family: ShabnamDigit, "IRANSans", "Tahoma" !important;
}
:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}
::-webkit-input-placeholder,
:-moz-placeholder {
  font-family: shabnamDigit, "IRANSans", "Tahoma";
  font-size: 14px;
}
::placeholder {
  font-family: shabnamDigit, "IRANSans", "Tahoma";
  font-size: 14px;
  color: #6c6c6c;
  font-weight: 400;
}
input[type="tel"]:focus,
input[data-border="border"]:focus {
  outline: none;
  border: 1px solid #7549c2 !important;
}
.donutchart-innertext-value {
  display: none;
}
// =============================== TreeChart=======================================
.flowchart-zoom {
  right: unset !important;
  left: 10px;
  top: 50px !important;
}

.rect__element {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: fit-content;
  width: 270px;
  border: 2px solid #7549c2;
  display: flex;
  justify-content: center;
  padding: 10px 11px 50px 11px;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
}
.rect__element__item,
.product__element__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f8fb;
  border-radius: 10px;
  padding: 10px;
  height: 47px;
  width: 248px;
  margin-bottom: 10px;
}
.product__element__item {
  background-color: #f7f5fb;
}

.add__item-to__rect {
  height: 40px;
  width: 248px;
  background-color: #ef5da8;
  color: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: shabnamDigit;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 8px;
  bottom: 10px;
}
// div {
//   display: flex;
//   flex-direction: column;
//   margin: 10px 0;
//   font: 18px serif;
//   height: fit-content;
//   width: 150px;
//   border: 1px solid green;
//   display: flex;
//   justify-content: center;
//   padding: 10px;
//   border-radius: 5px;
//   color: #7549c2;
// }
// #id_12 {
//   border: 2px solid #7549c2;
//   display: flex;
//   justify-content: center;
//   // padding: 10px;
//   border-radius: 5px;
//   color: #7549c2;
// }
#div input {
  border: 1px solid #7549c2;
  width: 100px;
  border-radius: 5px;
  height: 20px;
  outline: none;
}
#div input:focus {
  border: 1px solid blue;
}
// #id_12,
// #id_10 {
//   background-color: #fff;
// }
.rightArrow:active {
  transform: translateX(2px);
}
.leftArrow:active {
  transform: translateX(-2px);
}
.downArrow:active {
  transform: translateY(2px);
}
.topArrow:active {
  transform: translateY(-2px);
}
.correctP {
  padding: 10px 20px 0 20px;
}
.MuiBottomNavigationAction-label{
  margin-right: 5px;
}
.MuiBottomNavigationAction-root{
  flex-direction: row !important;
}
.MuiBottomNavigationAction-root{
  padding: 0 1px !important;
  max-width: 183px !important;
}
.MuiBottomNavigationAction-root.Mui-selected {
  background-color: #8e6ec552;
  border-radius: 50px;
  height: 43px;
  margin-top: 7px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #7549c290 !important;
}
.MuiInputLabel-formControl{
  background-color: #fff;
  padding: 0 7px !important;
}
@media only screen and (max-width: 700px) {
  .correctP {
    padding: 15px 10px 0 10px;
  }
  .mt-2 {
    margin-top: 10px !important;
  }
}
