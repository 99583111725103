
@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/eot/iranyekanwebboldfanum.eot');
    src: url('../fonts/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/woff/iranyekanwebboldfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/ttf/iranyekanwebboldfanum.ttf') format('truetype');
}

// @font-face {
//     font-family: 'yekan';
//     font-style: normal;
//     font-weight: 100;
//     src: url('../fonts/eot/iranyekanwebthinfanum.eot');
//     src: url('../fonts/eot/iranyekanwebthinfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//     url('../fonts/woff/iranyekanwebthinfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//     url('../fonts/ttf/iranyekanwebthinfanum.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'yekan';
//     font-style: normal;
//     font-weight: 300;
//     src: url('../fonts/eot/iranyekanweblightfanum.eot');
//     src: url('../fonts/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//     url('../fonts/woff/iranyekanweblightfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//     url('../fonts/ttf/iranyekanweblightfanum.ttf') format('truetype');
// }

@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/eot/iranyekanwebregularfanum.eot');
    src: url('../fonts/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/woff/iranyekanwebregularfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/ttf/iranyekanwebregularfanum.ttf') format('truetype');
}

@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/eot/iranyekanwebmediumfanum.eot');
    src: url('../fonts/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/woff/iranyekanwebmediumfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
}

@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/eot/iranyekanwebextraboldfanum.eot');
    src: url('../fonts/eot/iranyekanwebextraboldfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/woff/iranyekanwebextraboldfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/ttf/iranyekanwebextraboldfanum.ttf') format('truetype');
}

@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/eot/iranyekanwebblackfanum.eot');
    src: url('../fonts/eot/iranyekanwebblackfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/woff/iranyekanwebblackfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/ttf/iranyekanwebblackfanum.ttf') format('truetype');
}

@font-face {
    font-family: 'Shabnam';
    src: url("../fonts/Shabnam.eot");
    src: url("../fonts/Shabnam.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Shabnam.woff2") format("woff2"),
      url("../fonts/Shabnam.woff") format("woff"),
      url("../fonts/Shabnam.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Shabnam';
    src: url("../fonts/Shabnam-Bold.eot");
    src: url("../fonts/Shabnam-Bold.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Shabnam-Bold.woff2") format("woff2"),
      url("../fonts/Shabnam-Bold.woff") format("woff"),
      url("../fonts/Shabnam-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Shabnam';
    src: url("../fonts/Shabnam-Thin.eot");
    src: url("../fonts/Shabnam-Thin.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Shabnam-Thin.woff2") format("woff2"),
      url("../fonts/Shabnam-Thin.woff") format("woff"),
      url("../fonts/Shabnam-Thin.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Shabnam';
    src: url("../fonts/Shabnam-Light.eot");
    src: url("../fonts/Shabnam-Light.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Shabnam-Light.woff2") format("woff2"),
      url("../fonts/Shabnam-Light.woff") format("woff"),
      url("../fonts/Shabnam-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Shabnam';
    src: url("../fonts/Shabnam-Medium.eot");
    src: url("../fonts/Shabnam-Medium.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Shabnam-Medium.woff2") format("woff2"),
      url("../fonts/Shabnam-Medium.woff") format("woff"),
      url("../fonts/Shabnam-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'ShabnamDigit';
    src: url("../fonts/Farsi-Digits/Shabnam-Medium-FD.eot");
    src: url("../fonts/Farsi-Digits/Shabnam-Medium-FD.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Farsi-Digits/Shabnam-Medium-FD.woff2") format("woff2"),
      url("../fonts/Farsi-Digits/Shabnam-Medium-FD.woff") format("woff"),
      url("../fonts/Farsi-Digits/Shabnam-Medium-FD.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
  }